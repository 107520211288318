import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Register, Landing, Error, ProtectedRoute, CompleteProfile, Demande } from './pages';
import { Profile, SharedLayout, Stats, AddAccident, Diagnostic, Diagnostic2, AddUser, Users, UsersPending } from './pages/dashboard';
import { useAppContext } from './context/appContext'; // Import the AppContext

function App() {
  const { user } = useAppContext(); // Get the user from the AppContext

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            <ProtectedRoute>
              <SharedLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<AddAccident />} />
          <Route path='diagnostic' element={<Diagnostic />} />
          <Route path='diagnostic2' element={<Diagnostic2 />} />
          <Route path='profile' element={<Profile />} />
          <Route path='complete-profile' element={<CompleteProfile />} />
          
          {/* Conditionally render the stats route if the user email matches */}
          {user?.email === 'registreorthotunisie@gmail.com' && (
            <>
              <Route path='stats' element={<Stats />} />
              <Route path='add-user' element={<AddUser />} />
              <Route path='users' element={<Users />} />
              <Route path='usersPending' element={<UsersPending />} />
            </>
          )}
        </Route>

        <Route path='/demande' element={<Demande />} />
        <Route path='/register' element={<Register />} />
        <Route path='/landing' element={<Landing />} />
        <Route path='*' element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
