export const DISPLAY_ALERT = 'SHOW_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';
export const DISPLAY_ALERT_DATE_NAISSANCE = 'DISPLAY_ALERT_DATE_NAISSANCE';
export const DISPLAY_ALERT_DATE_ACCIDENT = 'DISPLAY_ALERT_DATE_ACCIDENT';
export const DISPLAY_ALERT_RESET = 'DISPLAY_ALERT_RESET';
export const DISPLAY_ALERT_REQUEST_ACCOUNT = 'DISPLAY_ALERT_REQUEST_ACCOUNT';




export const SETUP_USER_BEGIN = 'SETUP_USER_BEGIN';
export const SETUP_USER_SUCCESS = 'SETUP_USER_SUCCESS';
export const SETUP_USER_ERROR = 'SETUP_USER_ERROR';

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const LOGOUT_USER = 'LOGOUT_USER';

export const UPDATE_USER_BEGIN = 'UPDATE_USER_BEGIN';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const HANDLE_CHANGE = 'HANDLE_CHANGE';
export const CLEAR_VALUES = 'CLEAR_VALUES';

export const SHOW_STATS_BEGIN = 'SHOW_STATS_BEGIN';
export const SHOW_STATS_SUCCESS = 'SHOW_STATS_SUCCESS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const CHANGE_PAGE = 'CHANGE_PAGE';

export const GET_CURRENT_USER_BEGIN = 'GET_CURRENT_USER_BEGIN';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';


export const CREATE_ACCIDENT_BEGIN = 'CREATE_ACCIDENT_BEGIN';
export const CREATE_ACCIDENT_SUCCESS = 'CREATE_ACCIDENT_SUCCESS';
export const CREATE_ACCIDENT_ERROR = 'CREATE_ACCIDENT_ERROR';

export const GET_ACCIDENTS_BEGIN = 'GET_ACCIDENTS_BEGIN';
export const GET_ACCIDENTS_SUCCESS = 'GET_ACCIDENTS_SUCCESS';

export const SET_EDIT_ACCIDENT = 'SET_EDIT_ACCIDENT';

export const DELETE_ACCIDENT_BEGIN = 'DELETE_ACCIDENT_BEGIN';
export const DELETE_ACCIDENT_ERROR = 'DELETE_ACCIDENT_ERROR';

export const EDIT_ACCIDENT_BEGIN = 'EDIT_ACCIDENT_BEGIN';
export const EDIT_ACCIDENT_SUCCESS = 'EDIT_ACCIDENT_SUCCESS';
export const EDIT_ACCIDENT_ERROR = 'EDIT_ACCIDENT_ERROR';

export const FETCH_SUMMARY_STATS_BEGIN = 'FETCH_SUMMARY_STATS_BEGIN';
export const FETCH_SUMMARY_STATS_SUCCESS = 'FETCH_SUMMARY_STATS_SUCCESS';
export const FETCH_SUMMARY_STATS_ERROR = 'FETCH_SUMMARY_STATS_ERROR';

export const GET_USERS_BEGIN = 'GET_USERS_BEGIN';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

export const DELETE_USER_BEGIN = 'DELETE_USER_BEGIN';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const APPROVE_USER_BEGIN = 'APPROVE_USER_BEGIN';
export const APPROVE_USER_SUCCESS = 'APPROVE_USER_SUCCESS';
export const APPROVE_USER_ERROR = 'APPROVE_USER_ERROR';

export const GET_USERSPENDING_SUCCESS = 'GET_USERSPENDING_SUCCESS'