import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../context/appContext';
import Wrapper from '../../assets/wrappers/DashboardFormPage';
import styled from 'styled-components';
import { Alert } from '../../components';
import ConfirmationModal from '../../components/ConfirmationModal'; // Import the custom modal

const Wrapper2 = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
  }

  th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f4f4f4;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  /* Adjust table for smaller screens */
  @media (max-width: 768px) {
    table {
      display: block;
      width: 100%;
      overflow-x: auto;
      white-space: nowrap;
    }

    th, td {
      font-size: 0.8rem;
      padding: 8px;
    }
  }

  @media (max-width: 480px) {
    th, td {
      font-size: 0.75rem;
      padding: 5px;
    }
  }

  .pagination {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    align-items: center;
  }

  .pagination button {
    padding: 0.5rem 1rem;
    border: 1px solid #ddd;
    background-color: #fff;
    cursor: pointer;
    border-radius: 4px;
  }

  .pagination button:disabled {
    background-color: #f4f4f4;
    cursor: not-allowed;
  }
`;

const AllUsersPending = () => {
  const {
    getAllUsersPending,
    usersPending = [],
    isLoading,
    showAlert,
    error,
    totalUsersPending,
    numOfPagesPending,
    deleteUserPending,
    approveUser,
    clearAlert,
  } = useAppContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [showConfirmation, setShowConfirmation] = useState(false); // State to control the modal
  const [userToDelete, setUserToDelete] = useState(null); // Store the user to delete
  const [userToApprove, setUserToApprove] = useState(null); // Store the user to delete
    const [isHoveredF, setIsHoveredF] = useState(false); // State to track hover
    const [isHoveredL, setIsHoveredL] = useState(false); // State to track hover


  // Fetch users only on initial load
  useEffect(() => {
    if (!isLoading && usersPending.length === 0) {
      getAllUsersPending(currentPage); // Fetch users only on initial load
    }
  }, [isLoading, usersPending.length, getAllUsersPending]);

  if (usersPending.length === 0) {
    return <p>Auncune demande de compte.</p>;
  }

  // Handle next page
  const handleNextPage = () => {
    if (currentPage < numOfPagesPending) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage); // Update currentPage state
      getAllUsersPending(nextPage); // Fetch users for the next page
    }
  };

  // Handle previous page
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage); // Update currentPage state
      getAllUsersPending(prevPage); // Fetch users for the previous page
    }
  };

  
  // Handle last page
  const handleLastPage = () => {
    if (currentPage < numOfPagesPending) {
      setCurrentPage(numOfPagesPending); // Update currentPage state
      getAllUsersPending(numOfPagesPending); // Fetch users for the next page
    }
  };

  // Handle first page
  const handleFirstPage = () => {
    if (currentPage > 1) {
      setCurrentPage(1); // Update currentPage state
      getAllUsersPending(1); // Fetch users for the next page
    }
  };


  // Handle approve user
  const handleApproveUser = async (email) => {
    await approveUser(email); // Call approveUser function with the email
    getAllUsersPending(currentPage); // Refresh the list of pending users
  };

  // Handle delete user
  const handleDeleteUser = (email) => {
    setUserToDelete(email); // Set the user to delete
    setShowConfirmation(true); // Show the confirmation modal
  };

  // Handle confirmation
  const handleConfirmDelete = async () => {
    setShowConfirmation(false); // Hide the modal
    if (userToDelete) {
      await deleteUserPending(userToDelete); // Call deleteUser function
      setCurrentPage(1); // Reset currentPage to 1
      getAllUsersPending(1); // Fetch users for the first page
    }
  };

  // Handle cancellation
  const handleCancelDelete = () => {
    setShowConfirmation(false); // Hide the modal
    setUserToDelete(null); // Clear the user to delete
  };

  // If still loading, show a loading message or spinner
  if (isLoading) {
    return <p>Chargement des utilisateurs...</p>;
  }

  // Handle errors if any
  if (error) {
    return <Alert message={error} onClose={clearAlert} />;
  }

  // Once loading is done, render the table with users
  return (
    <Wrapper>
      <Wrapper2>
        <h3>Tous les utilisateurs</h3>
        {showAlert && <Alert message={showAlert} onClose={clearAlert} />}
        <table>
          <thead>
            <tr>
              <th>Numéro</th>
              <th>Prénom</th>
              <th>Nom</th>
              <th>Email</th>
              <th>Ville</th>
              <th>Hopital</th>
              <th>Demande</th>
            </tr>
          </thead>
          <tbody>
            {usersPending.length > 0 ? (
              usersPending.map((user, index) => (
                <tr key={user._id}>
                  <td>{(currentPage - 1) * 20 + index + 1}</td>
                  <td>{user.name}</td>
                  <td>{user.lastName}</td>
                  <td>{user.email}</td>
                  <td>{user.location}</td>
                  <td>{user.hospital}</td>
                  <td>
                    <button
                      onClick={() => handleDeleteUser(user.email)}
                      className='btn btn-danger'
                      style={{ marginRight: '10px' }} // Add space between buttons
                    >
                      X
                    </button>
                    <button
                      onClick={() => handleApproveUser(user.email)}
                      className='btn btn-success'
                    >
                      ✔
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7">Aucun utilisateur trouvé</td>
              </tr>
            )}
          </tbody>
        </table>

        {/* Pagination Controls */}
        <div className="pagination">
          <button
            onClick={handleFirstPage}
            disabled={currentPage === 1}
            style={{
              border: 'none', // Remove the border
              background: 'none', // Remove the background
              padding: 0, // Remove padding
              font: 'inherit', // Use the same font as the parent
              color: currentPage === 1 ? '#ccc' : isHoveredF ? '#007bff' : '#000', // Black by default, blue on hover
              textDecoration: isHoveredF ? 'underline' : 'none', // Underline only on hover
              cursor: currentPage === 1 ? 'not-allowed' : 'pointer', // Change cursor based on disabled state
              transition: 'color 0.2s ease, text-decoration 0.2s ease', // Smooth transition for hover effect
              fontSize: '1.2em', // Slightly larger font size for better visibility
            }}
            onMouseEnter={() => setIsHoveredF(true)} // Handle hover in
            onMouseLeave={() => setIsHoveredF(false)} // Handle hover out
          >
            &lt;&lt; {/* HTML entity for >> */}
          </button>
          <button onClick={handlePreviousPage} disabled={currentPage === 1}>
            Précédent
          </button>
          <span>
            Page {currentPage} sur {numOfPagesPending}
          </span>
          <button onClick={handleNextPage} disabled={currentPage === numOfPagesPending}>
            Suivant
          </button>
          <button
            onClick={handleLastPage}
            disabled={currentPage === numOfPagesPending}
            style={{
              border: 'none', // Remove the border
              background: 'none', // Remove the background
              padding: 0, // Remove padding
              font: 'inherit', // Use the same font as the parent
              color: currentPage === numOfPagesPending ? '#ccc' : isHoveredL ? '#007bff' : '#000', // Black by default, blue on hover
              textDecoration: isHoveredL ? 'underline' : 'none', // Underline only on hover
              cursor: currentPage === numOfPagesPending ? 'not-allowed' : 'pointer', // Change cursor based on disabled state
              transition: 'color 0.2s ease, text-decoration 0.2s ease', // Smooth transition for hover effect
              fontSize: '1.2em', // Slightly larger font size for better visibility
            }}
            onMouseEnter={() => setIsHoveredL(true)} // Handle hover in
            onMouseLeave={() => setIsHoveredL(false)} // Handle hover out
          >
            &gt;&gt; {/* HTML entity for >> */}
          </button>
        </div>

        {/* Custom Confirmation Modal */}
        {showConfirmation && (
          <ConfirmationModal
            message={
              <span>
                Supprimer la demande de <strong>{userToDelete}</strong> ?
              </span>
            }
            onConfirm={handleConfirmDelete}
            onCancel={handleCancelDelete}
          />
        )}
      </Wrapper2>
    </Wrapper>
  );
};

export default AllUsersPending;