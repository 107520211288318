import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { useAppContext } from '../../context/appContext';
import Wrapper from '../../assets/wrappers/DashboardFormPage';
import styled from 'styled-components';
import { Alert } from '../../components';
import { useNavigate } from 'react-router-dom';

const Wrapper2 = styled.div`
  .file-input-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  #file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .file-input-label {
    display: inline-block;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #f0f0f2;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    color: #333;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 20px;
  }

  .file-input-label:hover {
    background-color: #f0f0f0;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
  }

  th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f4f4f4;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const AddUser = () => {
  const { showAlert, createUser } = useAppContext();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userRows, setUserRows] = useState([]);
  const [email, setEmail] = useState(''); // New state for single email
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);
  };

const handleFileUpload = async (e) => {
  e.preventDefault();
  if (!file) {
    alert('Veuillez choisir un fichier Excel.');
    return;
  }

  setLoading(true);

  const reader = new FileReader();
  reader.onload = async (e) => {
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

    const newUserRows = [];
    let allUsersCreatedSuccessfully = false;

    for (let i = 0; i < rows.length; i++) {
      let [email] = rows[i];
      if (email) {
        // Remove trailing and leading spaces
        email = email.trim().toLowerCase();
        const newUser = { name: email.split('@')[0], email };
        try {
          await createUser({
            currentUser: newUser,
            endPoint: 'add',
            alertText: `L'utilisateur ${email} a été créé avec succès !`,
          });

          newUserRows.push([email, 'Created']);
        } catch (error) {
          console.error(`Erreur lors de la création de l'utilisateur ${email} :`, error);
          newUserRows.push([email, 'Failed']);
          allUsersCreatedSuccessfully = true;
        }
      }
    }
    setUserRows(newUserRows);
    setLoading(false);

    if (!allUsersCreatedSuccessfully) {
      setTimeout(() => {
        navigate('/users');
        window.location.reload();
      }, 4000);
    }
  };

  reader.readAsArrayBuffer(file);
};


  const handleSingleUserCreation = async () => {
    if (!email) {
      alert("Veuillez entrer une adresse e-mail.");
      return;
    }

    const newUser = { name: email.split('@')[0], email };
    try {
      setLoading(true);
      await createUser({
        currentUser: newUser,
        endPoint: 'add',
        alertText: `L'utilisateur ${email} a été créé avec succès !`,
      });
      alert(`L'utilisateur ${email} a été créé avec succès !`);
      setEmail(''); // Clear input field after success
      navigate('/users');
      window.location.reload();
    } catch (error) {
      console.error(`Erreur lors de la création de l'utilisateur ${email} :`, error);
      alert("Une erreur s'est produite. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <Wrapper2>
        <form onSubmit={handleFileUpload} className="table-container">
          <h3>Créer des utilisateurs avec un fichier Excel</h3>
          {showAlert && <Alert />}
          <div className="file-input-wrapper">
            <input
              type="file"
              name="file"
              onChange={handleFileChange}
              accept=".xlsx, .xls"
              id="file-input"
            />
            <label htmlFor="file-input" className="file-input-label">
              {file ? file.name : 'Cliquez pour choisir le fichier Excel'}
            </label>
          </div>
          {/* <div className="form-center" style={{ display: 'flex', justifyContent: 'center' }}>
            <button
              className="btn btn-block"
              style={{ width: '30%' }}
              type="submit"
              disabled={loading}
            >
              {loading ? 'Traitement...' : 'Créer des utilisateurs'}
            </button>
          </div> */}
          <div className="form-center" style={{ display: 'flex', justifyContent: 'center'}}>
            <button
              className="btn btn-block"
              style={{ width: '80%', maxWidth: '300px'}}
              type="submit"
              disabled={loading}
            >
              {loading ? 'Traitement...' : 'Créer des utilisateurs'}
            </button>
          </div>

        </form>

        <div   style={{height: '20px'}}></div>
        <hr style={{ borderTop: '1px dashed grey' }} />
        <div   style={{height: '20px'}}></div>

        <div className="single-user-container">
          <h3>Créer un utilisateur avec une adresse e-mail</h3>
          {showAlert && <Alert />}
          <div className="file-input-wrapper">
            <input
              type="email"
              placeholder="Entrer une adresse e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                width: '100%',
                marginBottom: '10px',
                padding: '0.375rem 0.75rem',
                borderRadius: 'var(--borderRadius)',
                background: 'var(--backgroundColor)',
                border: '1px solid var(--grey-200)',
              }}    
            />
          </div>
          <div   style={{height: '15px'}}></div>
          <div className="form-center" style={{ display: 'flex', justifyContent: 'center' }}>
            <button
              className="btn btn-block"
              style={{ width: '80%', maxWidth: '300px' }}
              onClick={handleSingleUserCreation}
              disabled={loading}
            >
              {loading ? 'Création...' : 'Créer un utilisateur'}
            </button>
          </div>
        </div>
      </Wrapper2>
    </Wrapper>
  );
};

export default AddUser;
