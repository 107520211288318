import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Diagnostic = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Open Google in a new tab
    window.open('https://play.google.com/store/apps/details?id=com.jle.acetabular&pli=1', '_blank');
    
    // Redirect to '/'
    navigate('/');
  }, [navigate]);

  return null; // No content is rendered since we're redirecting
};

export default Diagnostic;
